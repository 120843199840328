import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import PaginationComponent from "components/Pagination/Pagination";
import usePagination from "hooks/usepagination";
import Loading from "components/Loading";
import newApi from "service/newApi";

import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  Button,
  Col,
  FormGroup,
} from "reactstrap";
import TreeSelect from "components/TreeSelect";

const Audios = () => {
  const { register, handleSubmit, reset } = useForm();
  const [info, setInfo] = useState([]);
  const { setActualPage, actualPage } = usePagination();
  const [offset, setOffset] = useState(actualPage || 1);
  const [onLoad, setOnLoad] = useState(false);
  const [limit, setLimit] = useState(100);
  const [query, setQuery] = useState("");
  const [pathId, setPathId] = useState(null);
  const [folders, setFolders] = useState([]);

  const onSubmit = (data) => {
    const { audioId } = data;

    let query = "";

    if (!pathId) {
      toast.info("É necessário selecionar uma fila para recuperar os áudios.");
      return;
    }

    if (audioId) {
      query += `audioName=${audioId}`;
    }

    setQuery(query);
    handleGetAudios(query);
  };

  const handleGetAudios = (query, newLimit) => {
    setInfo([]);
    setOnLoad(true);

    newApi
      .get(
        `admin/v1/event-audios/folders/${pathId}/audios?page=${offset}&limit=${Number(
          newLimit ?? limit,
        )}&${query}`,
      )
      .then((response) => {
        if (response.status === 204 || !response.data.length) {
          toast.info("Não foram encontrados áudios para esta busca!");
          setOnLoad(false);
          return;
        }

        setInfo(response.data ?? []);
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar os áudios!");
        setOnLoad(false);
        return;
      });
  };

  useEffect(() => {
    setOnLoad(true);
    newApi
      .get(`admin/v1/event-audios/folders`)
      .then((response) => {
        if (response.status === 204 || !response.data.length) {
          toast.info("Não foram encontrados diretórios para os áudios!");
          setOnLoad(false);
          return;
        }

        const nodeMap = {};
        response.data.forEach((item) => {
          nodeMap[item.id] = {
            id: item.id.toString(),
            label: item.name,
            children: [],
          };
        });

        const tree = [];

        response.data.forEach((item) => {
          if (item.parentFolderId === null) {
            tree.push(nodeMap[item.id]);
          } else {
            nodeMap[item.parentFolderId].children.push(nodeMap[item.id]);
          }
        });

        function pruneChildren(nodes) {
          return nodes.map((node) => {
            if (node.children.length > 0) {
              node.children = pruneChildren(node.children); // Recursively prune
            } else {
              delete node.children; // Remove empty children
            }
            return node;
          });
        }

        setFolders(pruneChildren(tree));
        setOnLoad(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar os diretórios!");
        setOnLoad(false);
        return;
      });
  }, []);

  const clearSearch = () => {
    setPathId(null);
    reset();
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    handleGetAudios(query, event.target.value);
  };

  useEffect(() => {
    setActualPage(offset);
    if (offset !== actualPage) {
      handleGetAudios(query);
    }
  }, [offset]);

  return (
    <>
      <Container className="mt-2" fluid>
        <div className="header bg-gradient-info pb-7 pt-2 pt-md-4 d-flex align-items-center justify-content-center flex-column ">
          <div className="container-fluid">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg={5}>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">Fila</label>
                    <div className=" flex justify-content-center">
                      <TreeSelect
                        data={folders}
                        onChange={(selectedNode) => setPathId(selectedNode.id)}
                        pathId={pathId}
                        placeholder="Selecione uma fila"
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label className="text-white mr-2 ml-2">ID do Áudio</label>
                    <input
                      name="audioId"
                      id="code"
                      className="Input custom-input"
                      {...register("audioId")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={2} className="justify-content-end d-flex flex-column">
                  <FormGroup>
                    <Card>
                      <Button
                        className="Button"
                        disabled={onLoad}
                        type="submit"
                        style={{ height: "40px" }}
                      >
                        Buscar
                      </Button>
                    </Card>
                  </FormGroup>
                </Col>
                <Col lg={2} className="justify-content-end d-flex flex-column ">
                  <FormGroup>
                    <Card>
                      <Button
                        className="Button"
                        disabled={onLoad}
                        onClick={clearSearch}
                        style={{ height: "40px" }}
                      >
                        Limpar
                      </Button>
                    </Card>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Container>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex flex-column ml-2 pl-2">
                <p className="m-0 pl-2"> Limite </p>
                <select
                  name="limit"
                  id="limit"
                  className="Input custom-select"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </CardHeader>
              <Table className="align-items-start table-flush" responsive>
                {onLoad ? <Loading /> : ""}
                <thead className="thead-light">
                  <tr>
                    <th>Registro</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {info.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {new Date(item.date).toLocaleString("pt-BR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td>{item.name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  {info.length ? (
                    <p>Áudios retornados: {info.length}</p>
                  ) : (
                    <p>
                      OBS: Obrigatório selecionar a fila dos áudios para
                      realizar a listagem.
                    </p>
                  )}
                </nav>
                <nav aria-label="...">
                  <PaginationComponent
                    limit={limit}
                    offset={offset}
                    setOffset={setOffset}
                    maxReached={info.length < limit}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Audios;
