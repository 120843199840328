import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "service/api";
import Select from "react-select";
import debounce from "lodash.debounce";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Tooltip,
} from "reactstrap";
import { ReactComponent as XIcon } from "assets/icons/x_icon.svg";

const LibraryForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [term, setTerm] = useState("");
  const [terms, setTerms] = useState([]);
  const [references, setReferences] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  useEffect(() => {
    getClients();
  }, []);
  const getClients = async () => {
    try {
      const response = await api.get("/clients");
      const clientOptions = response.data.clients.map((client) => ({
        value: client.id,
        label: client.name,
      }));
      setClients(clientOptions);
    } catch (error) {
      toast.error("Erro ao recuperar clientes!");
    }
  };

  const fetchLibraries = async (query, client) => {
    setLoadingOptions(true);
    try {
      const response = await api.get(
        `/libraries/autocomplete?search=${query}&client_id=${client.value}`,
      );
      const libraryOptions = response.data.map((library) => ({
        label: library.title,
        value: library.id,
      }));
      setOptions(libraryOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOptions(false);
    }
  };

  const debouncedFetchLibraries = useCallback(
    debounce((query) => fetchLibraries(query, selectedClient), 300),
    [selectedClient],
  );

  const onSubmit = async (data) => {
    if (terms.length === 0) {
      toast.error("É obrigatório adicionar pelo menos um termo");
      return;
    }
    setLoading(true);
    data.clientId = selectedClient.value;
    data.terms = terms.map((t) => ({ term: t }));
    data.referencedLibraries = references.map((ref) => ref.value);
    try {
      await api.post("/libraries", data);
      toast.success("Biblioteca cadastrada com sucesso!");
      setLoading(false);
      setTerms([]);
      setReferences([]);
      setOptions([]);
      setDescription("");
      setTitle("");
      reset();
    } catch (error) {
      setLoading(false);
      if (error.response.status === 400) {
        if (error.response.data.message === "Title already exists.") {
          toast.error("Já existe uma biblioteca com esse título");
        } else {
          toast.error("Erro ao cadastrar biblioteca");
        }
      }
    }
  };

  const handleAddTerm = (e) => {
    e.preventDefault();
    clearErrors("term");
    if (term.trim() && !term.startsWith("@") && !terms.includes(term.trim())) {
      setTerms([...terms, term.trim()]);
      setTerm("");
    }
  };

  const handleRemoveTerm = (termToRemove) => {
    setTerms(terms.filter((t) => t !== termToRemove));
  };

  const handleRemoveReference = (referenceId) => {
    setReferences(references.filter((ref) => ref.value !== referenceId));
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setTerm(inputValue);
    clearErrors("term");
    if (inputValue.startsWith("@") && inputValue.length > 2) {
      debouncedFetchLibraries(inputValue.slice(1));
    } else {
      setOptions([]);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    if (!references.some((ref) => ref.value === suggestion.value)) {
      setReferences([...references, suggestion]);
      setTerm("");
      setOptions([]);
    }
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Container className="mt-7" fluid>
      <Col xl="10">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Cadastro de Biblioteca</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form id="create-library" onSubmit={handleSubmit(onSubmit)}>
              <div className="pl-lg-4">
                <Row>
                  <Col md="12" lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="client-select"
                      >
                        Cliente
                      </label>
                      <Select
                        id="client-select"
                        options={clients.sort((a, b) =>
                          a.label.localeCompare(b.label),
                        )}
                        value={selectedClient}
                        onChange={(selectedOption) =>
                          setSelectedClient({
                            value: selectedOption.value,
                            label: selectedOption.label,
                          })
                        }
                        placeholder="Selecione um cliente"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="8">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="title">
                        Título
                      </label>
                      <input
                        id="title"
                        className="form-control"
                        placeholder="Título"
                        disabled={!selectedClient}
                        type="text"
                        {...register("title")}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="description"
                      >
                        Descrição
                      </label>
                      <input
                        id="description"
                        className="form-control"
                        disabled={!selectedClient}
                        placeholder="Descrição"
                        type="text"
                        {...register("description")}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="8">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="term">
                        Adicionar Termo{" "}
                        <i className="fas fa-info-circle" id="libinfo" />
                        <Tooltip
                          placement="right"
                          isOpen={tooltipOpen}
                          target="libinfo"
                          toggle={toggleTooltip}
                        >
                          <div style={{ margin: "0", padding: "0" }}>
                            Para usar outra biblioteca como referência, digite @
                            e o título da biblioteca desejada.
                          </div>
                        </Tooltip>
                      </label>
                      <input
                        id="term"
                        className="form-control"
                        placeholder="Digite um termo e pressione Enter"
                        autoComplete="off"
                        type="text"
                        value={term}
                        disabled={!selectedClient}
                        onChange={handleInputChange}
                        onKeyPress={(e) =>
                          e.key === "Enter" && handleAddTerm(e)
                        }
                      />
                      {loadingOptions && <div>Carregando...</div>}
                      {options.length > 0 && (
                        <ul
                          className="list-group mt-2"
                          style={{
                            position: "absolute",
                            zIndex: 1000,
                            backgroundColor: "white",
                            width: "100%",
                          }}
                        >
                          {options
                            .filter(
                              (option) =>
                                !references.some(
                                  (ref) => ref.value === option.value,
                                ),
                            )
                            .map((option) => (
                              <li
                                key={option.value}
                                className="list-group-item list-group-item-action"
                                onClick={() => handleSelectSuggestion(option)}
                              >
                                {option.label}
                              </li>
                            ))}
                        </ul>
                      )}
                      {errors.term && (
                        <span className="text-danger">
                          *{errors.term.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" lg="8">
                    <FormGroup>
                      <div
                        id="selected-items"
                        className="form-control"
                        style={{
                          height: "auto",
                          minHeight: "50px",
                          maxHeight: "200px",
                          overflow: "auto",
                          backgroundColor: !selectedClient ? "#e9ecef" : "#fff",
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          padding: "5px",
                          gap: "8px",
                        }}
                      >
                        {terms.map((t, index) => (
                          <div
                            key={`term-${index}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                              background: "rgb(230, 230, 230)",
                              color: "rgb(51, 51, 51)",
                              borderRadius: "0px",
                            }}
                          >
                            {t}
                            <button
                              type="button"
                              className="select__multi-value__remove hover-remove"
                              style={{
                                marginLeft: "5px",
                                background: "rgb(230,230,230)",
                                color: "rgb(51, 51, 51)",
                                border: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveTerm(t)}
                            >
                              <XIcon />
                            </button>
                          </div>
                        ))}
                        {references.map((ref, index) => (
                          <div
                            key={`ref-${index}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                              background: "rgb(230, 230, 230)",
                              color: "rgb(51, 51, 51)",
                              borderRadius: "0px",
                            }}
                          >
                            @{ref.label}
                            <button
                              type="button"
                              className="select__multi-value__remove hover-remove"
                              style={{
                                marginLeft: "5px",
                                background: "rgb(230,230,230)",
                                color: "rgb(51, 51, 51)",
                                border: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveReference(ref.value)}
                            >
                              <XIcon />
                            </button>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />
              <Button type="submit" disabled={!selectedClient}>
                {loading ? "Salvando..." : "Adicionar"}
              </Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default LibraryForm;
