import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import api from "service/api";
import ModalPipelineLibraryConfiguration from "components/Modal/ModalPipelineLibraryConfiguration";
import { useFormContext } from "react-hook-form";

const CustomTag = ({
  data,
  removeProps,
  setDisplay,
  setSelectedOptionForModal,
  configuration,
  isConfigured,
}) => {
  const { setValue, getValues } = useFormContext();
  return (
    <div
      onClick={() => {
        setSelectedOptionForModal({ ...data, ...configuration });
        setDisplay(true);
      }}
      style={{
        cursor: "pointer",
        padding: "2px 0 2px 8px",
        backgroundColor: isConfigured ? "#e2fee2" : "#e1e1e1",
        display: "inline-flex",
        alignItems: "center",
        margin: "4px",
        fontSize: "12px",
        borderRadius: "4px",
        border: isConfigured ? "1px solid green" : "none",
      }}
    >
      {data.label}
      <button
        onClick={(e) => {
          e.stopPropagation();
          const updatedValue = getValues("library").filter(
            (option) => option.value !== data.value,
          );
          setValue("library", updatedValue);
          removeProps.onClick();
        }}
        style={{
          marginLeft: "6px",
          border: "none",
          background: "transparent",
          cursor: "pointer",
          color: "#888",
          fontSize: "14px",
          transition: "color 0.2s",
        }}
        onMouseEnter={(e) => (e.target.style.color = "#333")}
        onMouseLeave={(e) => (e.target.style.color = "#888")}
      >
        ×
      </button>
    </div>
  );
};

const LibrariesSelect = ({ client, onChange, value, setValue, getValues }) => {
  const [options, setOptions] = useState([]);
  const [toggleModal, setModalDisplay] = useState(false);
  const [selectedOptionForModal, setSelectedOptionForModal] = useState(null);

  const fetchOptions = debounce(async (inputValue, callback) => {
    try {
      const response = await api.get(
        `/libraries/autocomplete/pipeline?search=${inputValue}&clientId=${client}`,
      );

      const fetchedOptions = response.data.map((item) => ({
        value: item.id,
        label: item.title,
      }));

      setOptions(fetchedOptions);
      callback(fetchedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
      callback([]);
    }
  }, 300);

  const handleModalClose = (updatedData) => {
    const currentConfigurations = getValues("libraryConfigurations") || {};
    const updatedConfigurations = {
      ...currentConfigurations,
      [selectedOptionForModal.value]: updatedData,
    };

    setValue("libraryConfigurations", updatedConfigurations);

    setModalDisplay(false);
  };

  return (
    <>
      <AsyncSelect
        isMulti
        cacheOptions
        loadOptions={(inputValue, callback) => {
          if (inputValue.startsWith("@")) {
            inputValue = inputValue.substring(1);
          }

          if (!inputValue.trim() || inputValue.length < 2) {
            callback([]);
            return;
          }

          fetchOptions(inputValue, callback);
        }}
        placeholder="Pesquisar biblioteca (Digite 2 caracteres ou mais)..."
        onChange={(selected) => {
          setValue("library", selected || []);
          onChange(selected || []);
        }}
        value={value}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({ ...base, minHeight: "40px" }), // Optional for consistent UI
        }}
        noOptionsMessage={() => "Nenhuma biblioteca encontrada."}
        loadingMessage={() => "Pesquisando..."}
        components={{
          MultiValue: (props) => {
            const configurations = getValues("libraryConfigurations") || {};
            return (
              <CustomTag
                {...props}
                setDisplay={setModalDisplay}
                setSelectedOptionForModal={setSelectedOptionForModal}
                configuration={configurations[props.data.value]}
                isConfigured={!!configurations[props.data.value]}
              />
            );
          },
        }}
      />
      {toggleModal && selectedOptionForModal && (
        <ModalPipelineLibraryConfiguration
          toggle={toggleModal}
          setToggle={setModalDisplay}
          onSave={handleModalClose}
          option={selectedOptionForModal}
        />
      )}
    </>
  );
};

export default LibrariesSelect;
