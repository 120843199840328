import { useEffect, useState, useCallback } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { toast } from "react-toastify";
import api from "service/api";
import Loading from "components/Loading";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import LibrariesSelect from "components/LibrariesSelect";

const ConfigurationContent = ({ register, errors, control }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    api
      .get(`/clients?page=1&limit=10000`)
      .then((response) => {
        setClients(
          response.data.clients.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar os clientes!");
        return;
      });
  }, []);

  return (
    <>
      <div style={{ fontSize: "14px", color: "#212529" }}>
        <div className="pl-12">
          <Col className="col-12">
            <FormGroup>
              <label className="form-control-label" htmlFor="title">
                Título*
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                {...register("title", {
                  required: "Por favor digite um título.",
                })}
              />
              {errors.title && (
                <span className="text-danger" style={{ fontSize: "0.85em" }}>
                  {errors.title.message}
                </span>
              )}
            </FormGroup>
          </Col>
        </div>
        <div className="pl-12">
          <Col className="col-12">
            <FormGroup>
              <label className="form-control-label" htmlFor="description">
                Descrição
              </label>
              <textarea
                className="form-control"
                style={{
                  width: "100%",
                  heigth: "100%",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  resize: "none",
                }}
                {...register("description")}
              ></textarea>
            </FormGroup>
          </Col>
        </div>
        <div className="pl-12">
          <Col className="col-12">
            <FormGroup>
              <label className="form-control-label" htmlFor="client">
                Cliente*
              </label>
              <Controller
                name="client"
                control={control}
                rules={{
                  required: "Por favor selecione um cliente.",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <Select
                      {...field}
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Selecione o cliente."
                      isSearchable={true}
                      isClearable={true}
                      options={clients}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                    />
                    {errors.client && (
                      <span
                        className="text-danger"
                        style={{ fontSize: "0.85em" }}
                      >
                        {errors.client.message}
                      </span>
                    )}
                  </>
                )}
              />
            </FormGroup>
          </Col>
        </div>
      </div>
    </>
  );
};

const TranscriptionSetup = ({
  register,
  errors,
  control,
  setValue,
  getValues,
}) => {
  const [languages, setLanguages] = useState([]);

  const selectedClient = useWatch({
    control,
    name: "client",
  });

  useEffect(() => {
    api
      .get(`/languages`)
      .then((response) => {
        setLanguages(
          response.data.map((item) => ({
            label: `${item.code}-${item.region}`,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar as linguagens disponíveis!");
        return;
      });
  }, []);

  return (
    <>
      <div className="pl-12">
        <Col className="col-12">
          <FormGroup>
            <label className="form-control-label" htmlFor="language">
              Idioma*
            </label>
            <Controller
              name="language"
              control={control}
              rules={{
                required: "Por favor selecione o idioma.",
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Selecione o idioma dos audios."
                    isSearchable={true}
                    isClearable={true}
                    options={languages}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                    }}
                  />
                  {errors.language && (
                    <span
                      className="text-danger"
                      style={{ fontSize: "0.85em" }}
                    >
                      {errors.language.message}
                    </span>
                  )}
                </>
              )}
            />
          </FormGroup>
        </Col>
      </div>

      <div className="pl-12">
        <Col className="col-12">
          <FormGroup>
            <label className="form-control-label" htmlFor="library">
              Bibliotecas*
            </label>
            <Controller
              name="library"
              control={control}
              rules={{
                required: "Por favor escolha uma ou mais bibliotecas.",
              }}
              render={({ field, fieldState }) => (
                <>
                  <LibrariesSelect
                    client={selectedClient?.value}
                    value={field.value}
                    onChange={(selected) => field.onChange(selected)}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                  />
                  {errors.library && (
                    <>
                      <span
                        className="text-danger"
                        style={{ fontSize: "0.85em" }}
                      >
                        {errors.library.message}
                      </span>
                      <br />
                    </>
                  )}
                </>
              )}
            />
            {!selectedClient && (
              <span className="text-danger" style={{ fontSize: "0.85em" }}>
                Selecione um cliente
              </span>
            )}
          </FormGroup>
        </Col>
      </div>
    </>
  );
};

const DataSourceSetup = ({ register, errors, control }) => {
  const [options, setOptions] = useState([]);
  const fetchFolders = useCallback(async () => {
    const response = await api.get(`/event-audios/folders`).catch((err) => {
      console.log(err);
      toast.error("Erro ao recuperar as filas disponíveis!");
      return null;
    });

    if (!response || response.status !== 200) return;
    const payload = response.data;

    const buildFolderMap = (folders) => {
      const folderMap = new Map();
      folders.forEach((folder) => folderMap.set(folder.id, folder));
      return folderMap;
    };

    const findLeafNodes = (folders) => {
      const parentIds = new Set(
        folders
          .map((folder) => folder.parentFolderId)
          .filter((id) => id !== null),
      );
      return folders.filter((folder) => !parentIds.has(folder.id));
    };

    const buildPath = (folder, folderMap) => {
      if (!folder.parentFolderId) return folder.name;
      const parentFolder = folderMap.get(folder.parentFolderId);
      return `${buildPath(parentFolder, folderMap)} / ${folder.name}`;
    };

    const transformToOptions = (folders) => {
      const folderMap = buildFolderMap(folders);
      const leafNodes = findLeafNodes(folders);
      return leafNodes.map((leaf) => ({
        value: leaf.id,
        label: buildPath(leaf, folderMap),
        filterLabel: leaf.name,
      }));
    };

    const formatted = transformToOptions(payload);
    setOptions(formatted);
  }, []);

  useEffect(() => {
    fetchFolders();
  }, []);

  return (
    <>
      <div className="pl-12">
        <Col className="col-12">
          <FormGroup>
            <label className="form-control-label" htmlFor="folders">
              Diretórios*
            </label>
            <Controller
              name="folders"
              control={control}
              rules={{
                required: "Por favor selecione um ou mais diretórios.",
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    {...field}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Selecione um ou mais diretórios."
                    isSearchable={true}
                    isClearable={true}
                    isMulti={true}
                    options={options}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(selectedOptions) => {
                      field.onChange(selectedOptions);
                    }}
                  />
                  {errors.folders && (
                    <span
                      className="text-danger"
                      style={{ fontSize: "0.85em" }}
                    >
                      {errors.folders.message}
                    </span>
                  )}
                </>
              )}
            />
          </FormGroup>
        </Col>
      </div>

      <div className="pl-12">
        <Col className="col-12">
          <FormGroup>
            <label className="form-control-label" htmlFor="query">
              Query*
            </label>
            <textarea
              name="query"
              className="form-control"
              style={{
                width: "100%",
                height: "350px",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                resize: "none",
              }}
              {...register("query", {
                required: "Por favor forneça a query a ser utilizada.",
              })}
            ></textarea>
            {errors.query && (
              <span className="text-danger" style={{ fontSize: "0.85em" }}>
                {errors.query.message}
              </span>
            )}
          </FormGroup>
        </Col>
      </div>
    </>
  );
};

const Pipeline = () => {
  const methods = useForm({
    shouldUnregister: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = methods;

  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const firstStepFields = useWatch({
    control,
    name: ["title", "client"],
  });
  const secondStepFields = useWatch({
    control,
    name: ["language", "library"],
  });
  const thirdStepFields = useWatch({
    control,
    name: ["folders", "query"],
  });

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const submit = async (data) => {
    setLoading(true);

    if (!firstStepFields[0] || !firstStepFields[1]) {
      toast.error(
        <>
          Preencha todos os campos da aba <strong>Configuração</strong>
        </>,
      );
      toggleAccordion(0);
      setLoading(false);
      return;
    }
    if (
      !secondStepFields[0] ||
      !secondStepFields[1] ||
      secondStepFields[1].length <= 0
    ) {
      toast.error(
        <>
          Preencha todos os campos da aba <strong>Transcrição</strong>
        </>,
      );
      toggleAccordion(1);
      setLoading(false);
      return;
    }
    if (!thirdStepFields[0] || !thirdStepFields[1]) {
      toast.error(
        <>
          Preencha todos os campos da aba <strong>Fonte de dados</strong>
        </>,
      );
      toggleAccordion(2);
      setLoading(false);
      return;
    }
    for (const library of data.library) {
      if (
        !data?.libraryConfigurations ||
        Object.keys(data?.libraryConfigurations).length <= 0
      ) {
        toast.error("Necessário configurar todas as bibliotecas!");
        toggleAccordion(1);
        setLoading(false);
        return;
      }
      if (!data?.libraryConfigurations[library.value]) {
        toast.error("Necessário configurar todas as bibliotecas!");
        toggleAccordion(1);
        setLoading(false);
        return;
      }
    }

    api
      .post("/pipeline", {
        title: data?.title,
        description: data?.description,
        client_id: data?.client?.value,
        language_id: data?.language?.value,
        query: data?.query,
        folders: data?.folders?.map((q) => q.value),
        libraries: data?.library?.map((library) => {
          return {
            id: library?.value,
            ...data.libraryConfigurations[library?.value],
          };
        }),
      })
      .then(() => {
        toast.success("Configuração de pipeline armazenada com sucesso!");
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.code === 5001) {
          toast.error("A query informada possui uma sintaxe inválida.");
          setLoading(false);
          return;
        }
        if (error.response?.data?.code === 5002) {
          toast.error("Não foi possível executar a query informada.");
          setLoading(false);
          return;
        }

        toast.error(
          "Houve um problema ao tentar cadastar a configuração do pipeline.",
        );
        setLoading(false);
      });
  };

  const items = [
    { title: "Configuração", content: ConfigurationContent },
    { title: "Transcrição", content: TranscriptionSetup },
    { title: "Fonte de dados", content: DataSourceSetup },
  ];

  return (
    <>
      <Container className="mt-7" fluid>
        <Col className="col-12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Criar configuração de pipeline</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody
              style={{
                position: "relative",
              }}
            >
              {loading ? <Loading /> : ""}

              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submit)} noValidate>
                  <div
                    style={{ border: "1px solid #ddd", borderRadius: "5px" }}
                  >
                    {items.map((item, index) => {
                      const isActive = activeIndex === index;
                      return (
                        <div key={index}>
                          {/* Accordion Header */}
                          <div
                            onClick={() => toggleAccordion(index)}
                            style={{
                              padding: "10px 15px",
                              backgroundColor: "#f8f9fa",
                              borderBottom: "1px solid #ddd",
                              cursor: "pointer",
                              fontWeight: "bold",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {item.title}
                            <span style={{ fontSize: "12px" }}>
                              {isActive ? "−" : "+"}
                            </span>
                          </div>

                          {/* Accordion Content */}
                          <div
                            style={{
                              maxHeight: isActive ? "800px" : "0",
                              overflow: "hidden",
                              transition: "max-height 0.5s ease-out",
                              padding: isActive ? "10px 15px" : "0 15px",
                              backgroundColor: "#fff",
                              borderBottom:
                                isActive && index !== items.length - 1
                                  ? "1px solid #ddd"
                                  : "none",
                            }}
                          >
                            {isActive && (
                              <div
                                style={{ fontSize: "14px", color: "#212529" }}
                              >
                                <item.content
                                  register={register}
                                  errors={errors}
                                  control={control}
                                  setValue={setValue}
                                  getValues={getValues}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <hr className="my-4" />
                  <Row className="d-flex justify-content-start align-items-center">
                    <Button type="submit">Salvar</Button>
                    <label style={{ fontSize: "0.8em", margin: 0 }}>
                      Todos os campos com <strong>*</strong> são obrigatórios.
                    </label>
                  </Row>
                </form>
              </FormProvider>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default Pipeline;
