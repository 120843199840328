import "../assets/css/PromptForm.css";
import { useEffect, useState } from "react";
import qs from "query-string";
import { toast } from "react-toastify";
import api from "service/api";
import Select from "react-select";
import qualityApi from "service/qualityApi";
import ScoreRuleGroup from "components/ScoreRule";
import ModalConfirm from "components/Modal/ModalConfirm";
import { NavLink } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";

const PromptScoreForm = () => {
  const [, setLoading] = useState(false);
  const [hasId, setHasId] = useState(false);
  const [scoreRuleGroup, setScoreRuleGroup] = useState(null);
  const [segments, setSegments] = useState([]);
  const [title, setTitle] = useState("");
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [availablePrompts, setAvailablePrompts] = useState([]);
  const [evaluableEvents, setEvaluableEvents] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [configuredRules, setConfiguredRules] = useState([]);

  useEffect(() => {
    const checkExpirationTime = localStorage.getItem("score_rule.expiration");

    if (checkExpirationTime) {
      const expirationTime = parseInt(checkExpirationTime);
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) {
        cleanScoreRulesStorage();
        return;
      }
    }
    const storedTitle = localStorage.getItem(`score_rule.title`);
    const storedClient = localStorage.getItem(`score_rule.client`);
    const storedSegment = localStorage.getItem(`score_rule.segment`);

    if (storedTitle) setTitle(storedTitle);
    if (storedClient) {
      const parsedClient = JSON.parse(storedClient);
      setSelectedClient(parsedClient);
    }
    if (storedSegment) {
      const parsedSegment = JSON.parse(storedSegment);
      setSelectedSegment(parsedSegment);
    }
  }, []);

  useEffect(() => {
    const id = getParamsId();
    api
      .get(`/clients?page=1&limit=${new Date().getTime()}`)
      .then((response) => {
        setClients(
          response.data.clients.map((item) => ({
            label: item.name,
            value: item.id,
          })),
        );
      })
      .catch(() => {
        toast.error("Erro ao recuperar os clientes!");
        return;
      });

    if (id) {
      cleanScoreRulesStorage();
      qualityApi
        .get(`/score/rule/${id}`)
        .then((response) => response.data)
        .then((response) => {
          setScoreRuleGroup(response);
          setTitle(response.title);
        })
        .catch(() => {
          toast.error("Erro ao recuperar o regras de score!");
          return;
        });
      setHasId(true);
    }
  }, []);

  useEffect(() => {
    if (selectedClient) {
      localStorage.setItem(`score_rule.client`, JSON.stringify(selectedClient));
    }
    if (selectedSegment) {
      localStorage.setItem(
        `score_rule.segment`,
        JSON.stringify(selectedSegment),
      );
    }
  }, [selectedClient, selectedSegment]);

  useEffect(() => {
    if (scoreRuleGroup?.clientId) {
      setSelectedClient(
        clients.find((client) => client.value == scoreRuleGroup.clientId),
      );
    }
  }, [scoreRuleGroup, clients]);

  useEffect(() => {
    if (selectedClient?.value) {
      api
        .get(
          `/segments?page=1&limit=${new Date().getTime()}&client=${
            selectedClient?.value
          }`,
        )
        .then((response) => {
          if (scoreRuleGroup) {
            const segment = response.data.segments.find(
              (segment) => segment.id == scoreRuleGroup.segmentId,
            );
            setSelectedSegment({
              label: segment.title,
              value: segment.id,
            });
          }
          setSegments(
            response.data.segments.map((item) => ({
              label: item.title,
              value: item.id,
            })),
          );
        })
        .catch(() => {
          toast.error("Erro ao recuperar os segmentos!");
          return;
        });
    }
  }, [selectedClient]);

  useEffect(() => {
    setAvailablePrompts([]);
    setEvaluableEvents([]);
    getPromptsAndEvaluableEventsFromSegment();
  }, [selectedSegment]);

  useEffect(() => {
    let tempScore = 0;
    const allValidRules = Object.values(configuredRules)
      .flat()
      .reduce((result, item) => {
        if (!result[item.groupId]) {
          result[item.groupId] = [];
        }
        result[item.groupId].push(item);
        return result;
      }, []);

    Object.values(allValidRules).forEach((rule) => {
      if (rule.length > 2) {
        if (!isNaN(Number(rule[rule?.length - 1].score))) {
          tempScore += Number(rule[rule?.length - 1].score);
        }
      } else {
        rule.forEach((rule) => {
          tempScore += Number(rule.score);
        });
      }
    });
    if (tempScore) setTotalScore(tempScore);
  }, [configuredRules]);

  function handleFieldsData(evt) {
    evt.preventDefault();

    if (!selectedSegment) {
      return toast.error("Selecione um segmento!");
    }

    const scoreRulePayload = {
      title: title,
      segmentId: selectedSegment.value,
      prompts: [],
      evaluableEvent: [],
    };

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith("score_rules.")) {
        const ruleData = JSON.parse(localStorage.getItem(key));
        const allRules = {};

        let priority = null;
        let type = ruleData[ruleData.length - 1].type;

        for (let i = 0; i < ruleData.length; i++) {
          let formattedRule = {};
          if (!allRules[ruleData[i].groupId]) {
            allRules[ruleData[i].groupId] = [];
          }

          if ("conditional" in ruleData[i]) {
            if (
              ruleData[i + 1].key !== "" &&
              ruleData[i + 1].condition !== "" &&
              ruleData[i + 1].value !== ""
            ) {
              formattedRule = {
                key: ruleData[i + 1].key,
                type: ruleData[i + 1].condition,
                value: ruleData[i + 1].value,
                score: Number(ruleData[i + 1].score),
                priority: ruleData[i + 1].priority ?? false,
              };

              priority = ruleData[i + 1].priority ?? false;

              allRules[ruleData[i].groupId] =
                ruleData[i].conditional === "OR"
                  ? [...allRules[ruleData[i].groupId], formattedRule]
                  : [...allRules[ruleData[i].groupId], [formattedRule]];
              if (i < ruleData.length - 2) {
                i++;
              } else {
                break;
              }
            }
            continue;
          }

          if (
            ruleData[i].key !== "" &&
            ruleData[i].condition !== "" &&
            ruleData[i].value !== ""
          ) {
            formattedRule = {
              key: ruleData[i].key,
              type: ruleData[i].condition || ruleData[i].conditional,
              value: ruleData[i].value,
              score: Number(ruleData[i].score),
              priority: ruleData[i].priority ?? false,
            };

            allRules[ruleData[i].groupId] =
              ruleData[i + 1] && ruleData[i + 1].conditional === "AND"
                ? [[formattedRule]]
                : [formattedRule];
          }
        }

        const rules = Object.values(allRules);

        if (rules?.length && rules.some((rule) => rule.score !== 0)) {
          if (rules[0] && rules[0][0]) {
            const newRule = {
              [type === "prompt" ? "promptId" : "evaluableEventId"]:
                key.split(".")[1],
              config: rules.some((rule) => rule?.length > 1)
                ? rules
                    .filter((rule) => rule.length)
                    .map((rule) => {
                      return {
                        rule: rule[0]?.length > 0 ? rule : [rule],
                        score: rule[rule.length - 1][0]
                          ? rule[rule.length - 1][0].score
                          : rule[rule.length - 1].score,
                        priority: priority ?? rule[0].priority,
                      };
                    })
                : rules
                    .filter((rule) => rule.length)
                    .map((rule) => ({
                      rule: [rule],
                      score: rule[0].score,
                      priority: rule[0].priority,
                    })),
            };

            if (type === "prompt") {
              scoreRulePayload.prompts.push(newRule);
            } else {
              scoreRulePayload.evaluableEvent.push(newRule);
            }
          }
        }
      }
    }
    if (
      !scoreRulePayload.evaluableEvent.length &&
      !scoreRulePayload.prompts.length
    ) {
      return toast.error("Não há regras válidas, verifique e tente novamente.");
    }

    onSubmit(scoreRulePayload);
  }

  function onSubmit(scoreRulePayload) {
    setLoading(true);

    if (!title.trim()) {
      return toast.error("Informe o título das regras de score");
    }

    if (!selectedSegment?.value) {
      return toast.error("Informe o segmento");
    }

    if (hasId) {
      console.log(hasId);
      qualityApi
        .put(`/score/rule`, scoreRulePayload)
        .then(() => {
          toast.success("Regras de score atualizadas com sucesso!");
          toast(
            <div>
              <p>Deseja visualizar regras atualizadas?</p>
              <button
                onClick={() => window.location.reload()}
                style={{
                  padding: "8px 16px",
                  background: "#4CAF50",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Visualizar
              </button>
            </div>,
            {
              className: "",
              bodyClassName: "",
              progressStyle: {
                background: "#4CAF50",
              },
            },
          );

          cleanScoreRulesStorage();
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.data.statusCode === 400) {
            return toast.error(
              "Houve um erro ao atualizar, verifique as regras e tente novamente!",
            );
          }
          toast.error("Erro ao atualizar a regras de score!");
        });
    } else {
      qualityApi
        .post("/score/rule", scoreRulePayload)
        .then((response) => {
          toast.success("Regras de score cadastradas com sucesso.");
          toast(
            <div>
              <p>Deseja visualizar regras criadas?</p>

              <NavLink
                style={{
                  padding: "8px 16px",
                  background: "#4CAF50",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                activeClassName="active"
                to={`/admin/prompt-score-form?id=${response.data.id}`}
                target="_blank"
                rel="noopener noreferrer"
                title="Ver detalhes"
              >
                Visualizar
              </NavLink>
            </div>,
            {
              className: "",
              bodyClassName: "",
              progressStyle: {
                background: "#4CAF50",
              },
            },
          );
          setLoading(false);
          cleanScoreRulesStorage();
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.data.statusCode === 400) {
            return toast.error(
              "Houve um erro ao criar, verifique as regras e tente novamente!",
            );
          }
          toast.error("Erro ao criar as regras de score!");
        });
    }
  }

  function getParamsId() {
    const queryParams = qs.parse(window.location.search);
    const id = queryParams.id;
    return id ? id : undefined;
  }

  function getPromptsAndEvaluableEventsFromSegment() {
    if (selectedSegment?.value) {
      api
        .get(
          `/prompts?page=1&limit=${new Date().getTime()}&segmentId=${
            selectedSegment.value
          }`,
        )
        .then((response) => {
          setAvailablePrompts(
            response.data.prompts
              .filter((prompt) => prompt.preStructuredSections?.length)
              .map((item) => ({
                label: item.title,
                content: item.preStructuredSections,
                value: item.id,
              })),
          );
        })
        .catch(() => {
          toast.error("Erro ao recuperar os prompts deste segmento!");
          return;
        });

      qualityApi
        .get(
          `/evaluable-events?page=1&limit=${new Date().getTime()}&segmentId=${
            selectedSegment.value
          }`,
        )
        .then((response) => {
          if (response.data.prompts) {
            setEvaluableEvents(
              response.data.prompts.map((item) => ({
                label: item.title,
                content: item.prompt,
                value: item.id,
              })),
            );
          }
        })
        .catch(() => {
          toast.error(
            "Erro ao recuperar os eventos avaliáveis deste segmento!",
          );
          return;
        });
    }
  }

  function cleanScoreRulesStorage() {
    setTitle("");
    setSelectedClient(null);
    setSelectedSegment(null);
    setAvailablePrompts([]);
    setEvaluableEvents([]);
    setTotalScore(0);
    setConfiguredRules([]);
    const keys = Object.entries(localStorage).map(([key, value]) => [
      key,
      value,
    ]);
    for (const key of keys) {
      if (key[0].startsWith("score_rule")) {
        localStorage.removeItem(key[0]);
      }
    }
  }

  return (
    <Container className="mt-6" fluid>
      <Col className="" xl="12">
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Cadastrar Regras de score</h3>
              </Col>
              <Col className="text-right" xs="4"></Col>
            </Row>
          </CardHeader>
          <CardBody>
            <form onSubmit={(evt) => handleFieldsData(evt)}>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="name">
                      Título
                    </label>
                    <input
                      className="input-title"
                      id="prompt-title"
                      placeholder="Título"
                      type="text"
                      value={title}
                      onChange={(evt) => {
                        setTitle(evt.target.value);
                        localStorage.setItem(
                          "score_rule.title",
                          evt.target.value,
                        );
                      }}
                      autoComplete="off"
                    />
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      Clientes
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Selecione um cliente"
                      value={selectedClient}
                      onChange={(client) => {
                        setSelectedSegment(null);
                        setAvailablePrompts([]);
                        setEvaluableEvents([]);
                        setSelectedClient(client);
                      }}
                      isClearable={true}
                      isSearchable={true}
                      options={clients}
                      isDisabled={hasId}
                    />
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="client">
                      Segmento
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Escolha um segmento"
                      value={selectedSegment}
                      onChange={setSelectedSegment}
                      isClearable={true}
                      isSearchable={true}
                      options={segments}
                      isDisabled={!selectedClient || hasId}
                    />
                  </FormGroup>
                </Col>
              </div>

              {availablePrompts?.length > 0 && (
                <ScoreRuleGroup
                  type="prompt"
                  promptsOrEvents={availablePrompts}
                  rules={scoreRuleGroup?.scoreRules}
                  setTotalScore={setTotalScore}
                  configuredRules={configuredRules}
                  setConfiguredRules={setConfiguredRules}
                />
              )}

              {evaluableEvents?.length > 0 && (
                <ScoreRuleGroup
                  type="evaluable-event"
                  promptsOrEvents={evaluableEvents}
                  rules={scoreRuleGroup?.scoreRules}
                  setTotalScore={setTotalScore}
                  configuredRules={configuredRules}
                  setConfiguredRules={setConfiguredRules}
                />
              )}

              <Alert color="warning" className="mt-4">
                <i className="ni ni-chat-round"></i>{" "}
                <b>Prioridade absoluta: </b> Ao selecionar prioridade absoluta
                para uma das regras, a nota de todo o evento será zerada caso a
                regra não seja atendida.
              </Alert>

              <hr className="my-4" />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <Button type="submit">
                  {hasId ? "Atualizar" : "Adicionar"}
                </Button>

                <ModalConfirm
                  id={hasId}
                  modalText={`Esta ação irá limpar todo o formulário de cadastro de regra de score, você tem certeza?`}
                  handler={() => {
                    cleanScoreRulesStorage();
                  }}
                  buttonStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "fit-content",
                  }}
                  disableButton={hasId}
                  buttonText="Limpar"
                />
                <span className="input-score">
                  <b>Score total: {totalScore}</b>
                </span>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default PromptScoreForm;
