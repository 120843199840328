import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./styles.css";
const TreeSelect = ({ data, placeholder, pathId, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [openNodes, setOpenNodes] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (node) => {
    setSelectedValue(node.label);
    onChange(node);
    setIsOpen(false);
  };
  const toggleNode = (id) => {
    setOpenNodes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  useEffect(() => {
    if (!pathId) {
      setSelectedValue(null);
    }
  }, [pathId]);

  const filterTree = (nodes, term) => {
    return nodes
      .map((node) => {
        if (node.label.toLowerCase().includes(term)) {
          return node;
        }

        if (node.children) {
          const filteredChildren = filterTree(node.children, term);
          if (filteredChildren.length > 0) {
            return { ...node, children: filteredChildren };
          }
        }

        return null;
      })
      .filter((node) => node !== null);
  };

  const renderTreeNodes = (nodes) => {
    return nodes.map((node) => (
      <div key={node.id} className="tree-node">
        <div
          className="tree-node-label"
          onClick={(e) => {
            e.stopPropagation();
            if (node.children) {
              toggleNode(node.id);
            } else {
              handleSelect(node);
            }
          }}
        >
          {node.label}
          {node.children && (
            <span
              className={classNames("toggle-icon", {
                open: openNodes[node.id],
              })}
            >
              {openNodes[node.id] ? " ▼ " : " ▶ "}
            </span>
          )}
        </div>
        {node.children && openNodes[node.id] && (
          <div className="tree-node-children">
            {renderTreeNodes(node.children)}
          </div>
        )}
      </div>
    ));
  };

  const filteredData = filterTree(data, searchTerm);

  return (
    <div className="tree-select">
      <div className="tree-select-input" onClick={toggleDropdown}>
        {selectedValue || placeholder}
        <span className={classNames("dropdown-icon", { open: isOpen })}>▼</span>
      </div>
      {isOpen && (
        <div className="tree-select-dropdown">
          <input
            type="text"
            className="tree-select-search"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {renderTreeNodes(filteredData)}
        </div>
      )}
    </div>
  );
};
export default TreeSelect;
